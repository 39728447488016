@import url('https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

html {
    scroll-behavior: smooth;
}

html, body, .home, #root {
    height: 100%;
    width: 100%;

    background-color: black;
}

.centered {
    text-align: center;
}

header {
    height: 86px;
    width: 100%;
    top: 0px;
    z-index: 99999999;
    position: fixed;
}

.header-black {
    background-color: #0b0b0b;
}

header .header-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 86px;
}

.header-flex .logo {
    flex: 1;
}

.header-flex .logo a {
    display: inline-block;
}

.header-flex .logo img {
    width: 75px;
    opacity: 0.7;
    transition: opacity 0.5s ease;
    cursor: pointer;
    display: block;
    margin-left: 15px;
    margin-top: 4px;
}

.header-flex .logo img:hover {
    opacity: 1;
}

.header-flex nav ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.header-flex nav>ul {
    height: 70px;
}

.header-flex nav>ul>li {
    height: 70px;
    line-height: 70px;
}

.header-flex nav>ul>li.separator-menu {
    border-left: 2px solid #546e7a;
    margin-left: 20px;
    margin-right: 20px;
    height: 30px;
}

.header-flex nav ul li a {
    padding: 10px 20px;
    text-decoration: none !important;
    font-size: 17px;
}

.header-flex nav ul li a,
.header-flex nav ul li a:hover,
.header-flex nav ul li a:focus,
.header-flex nav ul li a:active {
    color: #ffffff;
}

.header-flex nav ul li a.connectWallet {
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0px 0px 4px white;
    transition: box-shadow 0.3s ease-in-out;
}

.header-flex nav ul li a.connectWallet.walletConnected {
    color: #E7B551 !important;
    border: 1.5px solid #E7B551;
    box-shadow: 0px 0px 2px 1px #e7b551;
    font-weight: 500;
}

.header-flex nav ul li a.connectWallet.walletConnected svg {
    width: 20px;
    margin-right: 5px;
}

.menu-mobile {
    display: none;
    padding: 20px;
    font-size: 25px;
}

.mobile-close {
    text-align: right;
    font-size: 30px !important;
}

.mobile-close span {
    display: inline-block;
    /* background-color: black; */
    /* width: 33px; */
    /* height: 33px; */
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px white; */
    vertical-align: middle;
    text-align: center;
    line-height: 32px;
}

.mobile-close span svg {
    margin-right: 0px !important;
}

.mobile-close-li {
    display: none;
    margin-bottom: 20px !important;
}

.open-mobile-child {
    display: none;
}

.open-mobile-child-arrow {
    display: none;
}

.menu-mobile a {
    color: white;
    opacity: 0.7;
}

.menu-mobile a:hover {
    opacity: 1;
}

.icon-opensea-header {
    max-width: 20px;
    position: relative;
    top: -1.5px;
    vertical-align: middle;
}

.mintnow-menu {
    color: #e7b551 !important;
    font-weight: 500;
}

.tba-menu a {
    color: #9e9e9e !important;
}

.container-fluid {
    max-width: 100%;
    margin: 0px auto;
}

.container {
    max-width: 1260px;
    margin: 0px auto;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}

.container-padding {
    padding-top: 120px;
    padding-bottom: 100px;
}

.container-top {
    top: 86px;
    position: relative;
    padding-top: 15px;
    height: calc(100% - 86px);
}

h3 {
    font-size: 42px;
    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
    line-height: 58.8px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.height100 {
    height: 100%;
}

.homepage-intest-photo {
    height: 100%;
    background-color: black;
    position: relative;
}

.homepage-intest-photo .intest-fog-image,
.homepage-intest-photo .intest-fog,
.homepage-intest-photo .intest-shadow,
.homepage-intest-photo .intest-goats,
.homepage-intest-photo .intest-goat-omar,
.homepage-intest-photo .intest-bandiere {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
}

.intest-bandiere {
    background-size: 70px !important;
    height: 100%;
}

.homepage-intest-photo .intest-fog {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30.56%, #000000 100%);
}

.homepage-intest-photo div:not(.intest-bandiere) {
    background-size: contain !important;
    height: 100%;
}


h1.intest-h1 {
    text-align: center;
    position: relative;
    bottom: 220px;
    color: white;
    font-size: 64px;
    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
    line-height: 56px;
}

.homevideo-frame {
    text-align: center;
    position: relative;
}

.homevideo-frame img {
    max-width: 1000px;
    width: 90%;
}

.homevideo-frame .vector-play {
    left: calc(50% - 82.5px);
    top: calc(50% - 96px);
    position: absolute;
    width: 165px;
}

.vector-play p {
    color: white;
    font-weight: bold;
    font-size: 30px;
}

.section1-background {
    display: flex;
    flex-direction: row;
    background-position: center bottom -145px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    position: relative;
    height: 720px;
}

.section1-background .section1-left,
.section1-background .section1-right {
    flex: 1;
}

.section1-background .section1-right {
    max-width: 50%;
    float: right;
    text-align: right;
    padding-top: 150px;
}

.section1-background .section1-left {
    max-width: 50%;
    float: left;
    text-align: left;
}

.section1-background .section1-left img {
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.parag-section1 {
    font-size: 30px;
    line-height: 42px;
    color: white;
    padding-top: 0px;
}

.section2-background {
    position: relative;
    height: 700px;
}

.section2-background .section2-right {
    max-width: 50%;
    float: right;
    text-align: right;
}

.section2-background .section2-left {
    max-width: 50%;
    float: left;
    text-align: left;
    padding-top: 120px;
}

.section2-background img {
    width: 100%;
}

.faq-background {
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center bottom !important
}

.h3-section2 {
    font-size: 36px;
    line-height: 43.2px;
    margin-top: 20px;
}

.parag-section2 {
    font-size: 20px;
    line-height: 30.8px;
    padding-top: 0px;
    color: #848484;
    margin-top: 10px;
}

.parag-section-opacity {
    font-size: 20px;
    line-height: 30.8px;
    padding-top: 0px;
    color: white;
    opacity: 0.80;
    margin-top: 10px;
}

.parag-section3 {
    font-size: 20px;
    padding-top: 0px;
    color: #848484;
    margin-top: 10px;
    line-height: '28px';
    text-align: 'center';
}

.list-section2 {
    padding: 0px;
    margin: 40px 0px;
    color: white;
    list-style: none;
}

.list-section2 li {
    margin-bottom: 15px;
    font-family: 'Fahkwang', sans-serif;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
}

.list-section2 li svg {
    margin-right: 5px;
    width: 30px;
}

.section-goatrex-ul {
    padding: 10px 30px;
    margin: 0px;
    list-style: disc;
    color: #848484;
    font-size: 20px;
}

.section-goatrex-background {
    height: 650px;
    background-color: #0b1214;
}

.faq-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-column-gap: 2%;
    column-gap: 2%;

    opacity: 0.8;
    color: white;
    width: 100%;
    margin-top: 100px;
}

.faq-grid .faq-grid-element {
    flex: 48%;
}

.faq-grid h4 {
    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    margin: 0px;
    color: white;
}

.logo-footer {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 0px;
}

.team-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-column-gap: 2%;
    column-gap: 2%;

    opacity: 0.8;
    color: white;
    width: 100%;
    margin-top: 100px;
    text-align: center;

    row-gap: 70px;
}

.team-grid .team-grid-element {
    flex: 31%;
}

.team-grid .team-grid-element .team-grid-nome {
    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin: 0px;
}

.team-grid .team-grid-element .team-grid-ruolo {
    margin: 0px;
}

.team-grid-social-icon {
    color: #aaa !important;
    text-decoration: none !important;

    transition: color 0.5s ease;
}

.team-grid-social-icon:hover {
    color: white !important;
}

.team-grid-avatar {
    width: 210px;
    margin: 0px auto;
    display: block;
}

.team-grid-avatar img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.team-grid-social-icon svg {
    width: 33px;
    font-size: 21px;
}

.art-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 60px;
    margin-bottom: 50px;
}

.art-grid .art-grid-element {
    flex: 14%;
    margin-left: 15px;
    margin-right: 15px;

    margin-bottom: 30px;
}

.art-grid .art-grid-element img {
    width: 100%;
    border-radius: 30px;
}

.art-grid .art-grid-element:nth-child(1) {
    margin-top: 0px;
}

.art-grid .art-grid-element:nth-child(2) {
    margin-top: 80px;
}

.art-grid .art-grid-element:nth-child(3) {
    margin-top: 20px;
}

.art-grid .art-grid-element:nth-child(4) {
    margin-top: 70px;
}

.art-grid .art-grid-element:nth-child(5) {
    margin-top: 15px;
}

.art-grid .art-grid-element:nth-child(6) {
    margin-top: 55px;
}


.art-grid .art-grid-element:nth-child(7) {
    margin-top: -75px;
}

.art-grid .art-grid-element:nth-child(8) {
    margin-top: -5px;
}

.art-grid .art-grid-element:nth-child(9) {
    margin-top: -65px;
}

.art-grid .art-grid-element:nth-child(10) {
    margin-top: -15px;
}

.art-grid .art-grid-element:nth-child(11) {
    margin-top: -65px;
}

.art-grid .art-grid-element:nth-child(12) {
    margin-top: -25px;
}

.box-discordbtn {
    text-align: center;
}

.goat-nation .box-discordbtn,
.goat-fromday0 .box-discordbtn {
    display: inline;
}

.mintbtn-home {
    margin-right: 15px;
    color: #E7B551 !important;
    font-weight: bold !important;
}
.goat-nation .box-discordbtn {
    text-align: left;
}

.goat-fromday0 .box-discordbtn {
    text-align: right;
}

.goat-fromday0-decoration {
    width: 100%;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}

.box-discordbtn a {
    display: inline-block;
    padding: 15px 20px;
    background: black;
    border-radius: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none !important;

    box-shadow: 0px 0px 12px white;
    transition: box-shadow 0.3s ease-in-out;
}

.box-discordbtn a, 
.box-discordbtn a:active,
.box-discordbtn a:focus,
.box-discordbtn a:visited,
.box-discordbtn a:hover {
    color: white;
}

.box-discordbtn a:hover {
    box-shadow: 0px 0px 18px white;
}

.badge-goat {
    font-size: 20px;
    line-height: 32px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 3px 12px;
    display: inline-block;
    font-weight: bold;
}

.goatmap-background {
    position: relative;
    background-position: center 95px !important;
    background-repeat: no-repeat !important;

    background-size: cover !important;
    height: auto;
}

.roadmap-cart {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: white;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 330px;
}

.roadmap-cart .point-roadmap {
    flex: 14%;
    text-align: center;
    position: relative;

    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
}

.roadmap-cart .point-roadmap .linea {
    position: absolute;
}

.roadmap-cart .point-roadmap .bandiera {
    position: absolute;
    top: -106px;
    left: 50%;
}

.roadmap-cart .point-roadmap:nth-child(1) {
    top: 10px;
}

.roadmap-cart .point-roadmap:nth-child(2) {
    top: 100px;
}

.roadmap-cart .point-roadmap:nth-child(3) {
    top: -50px;
    left: -70px;
}

.roadmap-cart .point-roadmap:nth-child(4) {
    left: -110px;
}

.roadmap-cart .point-roadmap:nth-child(5) {
    left: -110px;
    top: -110px;
}

.roadmap-cart .point-roadmap:nth-child(6) {
    left: -100px;
    top: -70px;
}

.roadmap-cart .point-roadmap:nth-child(7) {
    left: -100px;
    top: 10px;
}

.roadmap-background {
    position: relative;
    background-position: center !important;
    background-repeat: no-repeat !important;

    background-size: cover !important;
    height: auto;
}

.roadmap-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.roadmap-grid .roadmap-grid-element {
    flex: 48%;
    margin-bottom: 20px;
}

.roadmap-grid .roadmap-active-hand {
    position: absolute;
    right: 20px;
    bottom: -20px;
}

.roadmap-grid .roadmap-grid-element .box-roadmap-element {
    background: rgb(20 20 20 / 50%);
    border-radius: 30px;
    max-width: 420px;
    margin: 0px auto;
    padding: 10px 15px;
    position: relative;

    transition: background-color 0.5s ease;
    cursor: pointer;
}

.box-roadmap-element h4 {
    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
    margin: 0px;
    color: white;
}

.box-roadmap-element ul {
    opacity: 0.5;
    color: white;
    font-size: 20px;
    line-height: 28px;
    margin: 0px;
    padding: 5px 15px;
    list-style: disc;
}

.box-roadmap-element.roadmap-active {
    background: rgb(255 255 255 / 70%) !important;
    color: black;
}

.box-roadmap-element.roadmap-active h4 {
    color: black;
}

.box-roadmap-element.roadmap-active ul {
    color: black;
}

.box-roadmap-element-hidden {
    display: none;
}

.riga_roadmap {
    position: absolute;
    top: 0px;
    width: 6px;
    left: calc(50% - 3px);
    height: 96%;

    background-repeat: no-repeat !important;
    background-position: center 0 !important;
    background-size: cover !important;
}


.intest-goatmap div {
    height: 100%;

    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
}

.goat-to-mars-background {
    height: 700px;
    position: absolute;
    top: -70px;
    left: 170px;
    width: 50%;
    z-index: -1;
}

.discpoint-roadmap {
    width: 20px;
    height: 20px;
    display: block;
    background-color: white;
    margin: 0px auto;
    margin-bottom: 0px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.roadmap {
    height: 370px !important;
    z-index: 99999;
    background-size: contain !important;
    width: 100%;

    position: absolute;
    top: 250px !important;
}

.intest-goatmap {
    height: 560px;
}

.hp-intest-photomobile,
.intest-goatmap_mobile,
.intest-goatart_mobile {
    display: none;
}

.goat-alphagoat-mobile,
.goat-goatrex-mobile,
.goat-academy-mobile {
    display: none;
}

.goat-alphagoat-background {
    margin-top: -100px;
    margin-bottom: -30px;
}

.dropdown {
    position: absolute;
    top: 65px;
    display: none !important;
    width: 230px;
    right: 0;
    text-align: left;
    box-shadow: 0px 0px 4px white;
    background-color: #0b0b0b;
    border-radius: 15px;
}

.dropdown a {
    padding: 10px 15px !important;
    display: block !important;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.dropdown li a svg {
    margin-right: 5px;
    width: 20px;
}

.dropdown li:not(:last-child) a {
    border-bottom: 1px solid #4A4A4A;
}

.dropdown li {
    line-height: initial;
}

.mostra-dropdown {
    display: block !important;
}

.lang-li {
    margin-right: 10px;
    position: relative;
}

.lang-li>ul a img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
    display: inline;
    vertical-align: middle;
    margin-right: 10px;
}

.lang-li>a,
.wallet-li a {
    cursor: pointer;
}

.wallet-li {
    position: relative;
    margin: 0px 20px;
}

.footer_copyright {
    text-align: right;
    color: #c6c6c6;
    margin: 0px;
}

.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: 999999999;
    top: 0px;
    display: none;

    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.modal-overlay .modal {
    position: relative;

    max-width: 550px;
    flex: 1 1;

    background: rgba(21, 19, 19, 0.93);
    border-radius: 30px;
    color: white;
    border: 1px solid #616161;
    padding: 10px 30px;
    padding-bottom: 20px;

    margin-left: 10px;
    margin-right: 10px;
}

.modal-overlay-inframe {
    left: 0px;

}

.modal-overlay.modal-show {
    display: flex;
}

.modal-overlay .modal h4 {
    margin-bottom: 20px;
    margin-top: 5px;
    text-align: center;
    font-size: 22px;

    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
}

.modal-overlay .modal .modal-close {
    position: absolute;

    right: -10px;
    top: -10px;
    background-color: black;
    width: 33px;
    height: 33px;
    text-align: center;
    font-size: 22px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px white;

    cursor: pointer;
}

.modal-overlay .modal .modal-close svg {
    vertical-align: middle;
    display: inline;
}

.modal .modal-select-wallets {
    display: flex;
    flex-direction: row;
}

.modal .modal-select-wallets .wallet-obj {
    flex: 50%;
    text-align: center;
    cursor: pointer;
}

.modal .modal-select-wallets .wallet-obj:not(:first-of-type) {
    border-left: 1px solid #616161;
}

.modal .modal-select-wallets .wallet-obj:hover {
    text-shadow: 0px 0px 10px white;
}

.modal .modal-select-wallets .wallet-obj p {
    margin: 0px;
}

.modal .modal-select-wallets .wallet-obj p.wallet-obj-title {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.modal .modal-select-wallets .wallet-obj p.wallet-obj-descr {
    margin-bottom: 30px;
    font-size: 12px;
}

.modal .modal-select-wallets .wallet-obj img {
    max-width: 120px;
    width: 100%;
}

.mint,
.account {
    height: 100%;
}

.mint .mint-page-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    height: 100%;
    align-items: center;
}

.mint .mint-page-column:nth-child(2) {
    z-index: 2;
}

.mint .container-mint {
    max-width: 90%;
    height: 100%;
    margin: 0px auto;
}

.mint .mint-page-columns .mint-page-column {
    flex: 50%;
}

.mint .mint-page-columns h1 {
    margin: 0px;
}

.mint h1 {
    margin: 0px;
    text-transform: uppercase;
    font-size: 64px;
    line-height: 64px;
    font-weight: 700;
    font-family: 'Fahkwang', sans-serif;
    color: white;
}

.box-mint-now {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.13);
    border-radius: 20px;
    color: white;

    padding: 15px 25px;
    max-width: 500px;
    margin: 0px auto;
    margin-top: 40px;
    padding-bottom: 20px;
}

.box-mint-now .box-mint-title {
    margin: 0px;

    text-shadow: 0px 0px 10px #FFFFFF;
    margin-bottom: 17px;
}

.box-mint-now .boxinput-mint-qnt {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    height: 40px;
    align-content: center;
    align-items: center;

    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: rgb(255 255 255 / 52%);

    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 12px;
}

.box-mint-now .boxinput-mint-qnt .mint-input-clmn1 {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
}

.box-mint-show {
    display: block;
}

.box-mint-hide {
    display: none;
}

.total-qnt {
    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
}

.total-qnt, .selector-mint-qnt {
    flex: 1;
    text-align: center;
}

.selector-mint-qnt {
    text-shadow: 0px 0 6px rgb(0 0 0 / 25%);

    transition: background 0.5s ease;
    margin-left: 7px;
    margin-right: 7px;

    cursor: pointer;

    font-size: 20px;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    line-height: 21px;

    text-align: center;
}

.selector-mint-qnt svg {
    vertical-align: middle;
    height: 20px;
    width: 20px;
}

.selector-mint-qnt:first-of-type {
    margin-left: 0px;
}

.selector-mint-qnt:hover {
    background-color: rgb(255 255 255 / 16%);
}

.box-mint-now .boxinput-mint-qnt .mint-input-clmn2 {
    text-align: right;

    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
}

.box-mint-now .boxinput-mint-qnt .mint-input-clmn2 svg {
    font-size: 15px;
    margin-left: 5px;
}

.boxtotal-mint {
    background-color: black !important;
    text-shadow: 0px 0px 10px #FFFFFF;
}

.mint-btn {
    width: 100%;
    display: block;
    min-height: 40px;
    appearance: none !important;
    border: 0px;

    font-family: 'Fahkwang', sans-serif;
    font-weight: 600;
    font-size: 18px;

    background: linear-gradient(180deg, #FFCC65 0%, #DBAA47 100%);

    color: #663e0a;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.mint-btn:hover {
    color: #424242;
    box-shadow: 0px 0px 3px white;
}

.pAvailable {
    font-family: 'Fahkwang', sans-serif;
    color: white;
    font-size: 17px;
    font-weight: 500;
}

.mint-goat {
    position: absolute;
    top: -1px;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    object-position: center;
    margin-left: -50px;
}

.mint-goat-background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.mint-goat-background .mint-goat-bandiere {
    height: 100%;
    width: 50%;
    position: absolute;
}

.mint-goat-background .mint-goat-shadow {
    height: 100%;
    width: 50%;
    position: absolute;
}

.mint-loading-data {
    margin-top: 50px;
}

.mint-loading-data svg {
    font-size: 32px;
    color: white;
}

.mint-soldout {
    color: white;
    margin-top: 50px;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
    font-style: italic;
    text-transform: uppercase;
    background-color: #f44336;
    display: inline-block;
    padding: 5px 20px;
}

.titleTxComplete {
    font-size: 50px;
    text-transform: none;
    font-family: 'Fahkwang', sans-serif;
    color: #9ccc65;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
}

.titleTxError {
    font-size: 40px;
    text-transform: none;
    font-family: 'Fahkwang', sans-serif;
    color: #e91e63;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 40px;
}

.mint-failed-tx {
    color: #f44336;
    margin-top: 10px;
    font-size: 21px;
    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
}

.mint-completed {
    color: #9ccc65;
    margin-top: 10px;
    font-size: 27px;
    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
}

.currentPhaseMint {
    margin-left: 5px;
    font-style: italic;
}

.mint-not-inwhitelist {
    color: white;
    margin-top: 50px;
    font-size: 28px;
    font-family: 'Fahkwang', sans-serif;
    background-color: rgb(96 125 139 / 65%);
    display: inline-block;
    padding: 5px 20px;
}

.modalError {
    position: fixed;
    max-width: 320px;

    background: rgba(21, 19, 19, 0.93);
    border-radius: 15px;
    color: white;
    border: 1px solid #616161;

    padding: 10px 30px;

    top: 100px;
    right: 30px;
    text-align: center;

    display: none;
    z-index: 9999999999;
}

.modalError.modalErrorShow {
    display: block;
}

.modalError h4 {
    margin-bottom: 0px;
    margin-top: 5px;
    text-align: center;
    font-size: 21px;

    font-weight: 600;
    font-family: 'Fahkwang', sans-serif;
}

.modalError p {
    margin-top: 10px;
}

.modalError .modal-close {
    position: absolute;

    right: -10px;
    top: -10px;

    background-color: black;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 19px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px white;
    cursor: pointer;
    line-height: 24px;
}

.btnOpensea {
    text-decoration: none !important;
    font-size: 19px;
    color: white;
    border: 1px solid #DDD;
    padding: 7px 10px;
    appearance: none;
    display: inline-block;
    margin-top: 23px;
    border-radius: 10px;
}

.only-mobile {
    display: none;
}

.container-account {
    background-color: rgb(221 221 221 / 46%);
    border-radius: 9px;
    

    background-color: rgba(255, 255, 255, 0.2);
    /* border: 1px solid rgba(255, 255, 255, 0.13); */
    border-radius: 15px;

    width: 90%;
    max-width: 1750px;
    margin: 0px auto;
    position: relative;

    color: white;
    
    height: calc(100% - 30px);
}

.container-account .account-logo-goat {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.02;
    z-index: 1;
    top: 0;
}

.account-columns {
    display: flex;
    height: 100%;
}

.account-columns .account-col1 {
    flex: 320px;

    background-color: rgb(117 117 117 / 29%);
    position: relative;
    border-radius: 15px 0px 0px 15px;
    z-index: 2;
}

.account-columns .account-col2 {
    flex: calc(100% - 320px);
    height: 100%;
    z-index: 2;
    position: relative;
    max-width: calc(100% - 320px);
}

.account-columns .account-col2 .account-padding {
    padding: 40px 60px;
}

.account-columns .account-col1 .account-col1-bg {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.14;
    background-position: top !important;
    z-index: -1;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.account .account-padding {
    padding: 40px 30px;
    height: 100%;
}
.account .account-padding.scroller {
    padding: 40px 30px;
    height: 100%;
    overflow-x: hidden;
}

.scroller-stylized::-webkit-scrollbar {
    width: 12px;
}
.scroller-stylized::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.scroller-stylized::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: rgb(206 206 206 / 84%);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
.scroller-stylized::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgb(206 206 206 / 84%);
}

.account h2 {
    margin: 0px;
    font-size: 26px;
    line-height: 34.2px;
    color: white;
}

.account .goat-logo-white {
    width: 90px;
    margin-top: -15px;
    margin-bottom: 10px;
}

.account .account-nav {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 33px;
    height: calc(100% - 250px);
    overflow-x: hidden;
}
.account .account-nav li:not(.nav-tba) {
    cursor: pointer;
}
.account .account-nav li {
    margin-bottom: 20px;
}

.account .account-nav li a {
    padding: 10px 15px;
    display: block;
    border: 1px solid #757575;
    border-radius: 30px;
    font-size: 18px;
}

.account .account-nav li svg {
    width: 20px;
    margin-right: 5px;
}

.account .account-nav li a,
.account .account-nav li a:hover,
.account .account-nav li a:active,
.account .account-nav li a:focus {
    color: white;
    text-decoration: none;
}

.account .account-nav li.nav-tba a {
    border: 1px solid #9e9e9e !important;
    color: #9e9e9e !important;
}

.account .account-nav li.nav-active a {
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
}

.account .account-nav li ul {
    margin-left: 25px;
    padding-left: 20px;
    list-style: none;
    border-left: 1px solid white;
    padding-top: 5px;
    position: relative;
    margin-bottom: 30px;
}
.account .account-nav li ul.subnav-ul {
    display: none;
}
.account .account-nav li ul.subnav-active {
    display: block;
}

.account .account-nav li ul li {
    margin-bottom: 0px;
}
.account .account-nav li ul li a {
    background-color: transparent !important;
    border: 0px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    padding: 10px 0px !important;
}
.account .account-nav li ul li:last-child a {
    padding-bottom: 0px !important;
}
.account .account-nav li ul li a::before {
    display: inline-block;
    content: "";
    border-top: 1px solid white;
    width: 15px;
    transform: translateY(11px);
    position: absolute;
    left: 0px;
}
.account .account-nav li ul li a.subnav-active {
    color: #e6b551 !important;
    font-weight: 600;
}

.badge-claim-intest {
    margin-top: 0px;
    margin-bottom: 60px;
}

.badge-claim-intest .badge-claim {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 18px;

    display: inline-block;
    padding: 6px 15px;
    border-radius: 30px;
    color: black;
    font-weight: 600;
    font-size: 18px;
}

.account-grid {
    margin-bottom: 50px;
    display: flex;
    max-width: 850px;
}

.account-grid .account-grid-col {
    flex: 1;
    font-weight: 600;
    font-size: 32px;
}

.badge-latte-toclaim {
    display: block;
    margin-top: 5px;
    font-weight: 700;
    color: #aefff0;
}

.badge-latte-wallet {
    display: block;
    margin-top: 5px;
    font-weight: 700;
    color: #a9ff65;
}

.badge-latte-power {
    display: block;
    margin-top: 5px;
    color: #aefff0;
}

.badge-latte-loading {
    display: block;
    max-width: 250px;
    width: 100%;
    height: 36px;
    margin-top: 10px;
}

.account-address-box {
    font-weight: 600;
    max-width: 260px;
    bottom: 30px;
    position: absolute;
    height: 60px;
    font-size: 18px;
}

.account-address-box span {
    display: block;
    word-wrap: break-word;
    margin-top: 5px;
}

.account-address-box svg {
    margin-right: 15px;
    width: 20px;
}

.account-grid-nftwallet {
    margin-bottom: 20px;
}

.sliderWalletNft {
    margin-left: -10px;
    margin-right: -10px;
}

.sliderWalletNft .thumb-nft {
    padding-left: 10px;
    padding-right: 10px;
}

.sliderWalletNft .thumb-nft img {
    max-width: 100%;
}

.sliderWalletNft .thumb-nft p {
    margin-top: 10px;
    line-height: initial;
    position: relative;
}

.sliderWalletNft .thumb-nft p span.num-token {
    float: left;
}

.sliderWalletNft .thumb-nft p span.roi-token {
    float: right;
    font-weight: 700;
    color: #aefff0;
}

.sliderWalletNft .thumb-nft p span.tooltip-date {
    top: -25px;
    background-color: rgb(11, 11, 11);
    border: 1px solid #4A4A4A;
    display: block;
    padding: 4px 6px;
    font-size: 15px;
    position: absolute;
    border-radius: 10px;
    width: max-content;
}

.sliderWalletNft .slick-prev,
.sliderWalletNft .slick-next {
    color: white;
    transition: color 0.5s ease;
}

.sliderWalletNft .slick-prev {
    left: -15px
}

.sliderWalletNft .slick-next {
    right: -15px
}

.sliderWalletNft .fa-angle-right:hover,
.sliderWalletNft .fa-angle-left:hover {
    color: #bdbdbd;
}

.sliderWalletNft .slick-track {
    margin-left: 0px;
}

.container-sliderWalletNft {
    min-height: 200px;
    max-width: 1160px;
}

.loading-nft {
    margin: 0px auto;
    display: block;
    font-size: 30px;
    top: 80px;
    position: relative;
}

.thumb-nft-img {
    position: relative;
}

.thumb-nft-img .gradient-loader-nft {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.gradient-loader {
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: gradient-loader-nft-effect;
    animation-timing-function: linear;

    background: linear-gradient(270deg, rgb(66 66 66 / 70%), rgb(112 112 112 / 70%));
    background-size: 400% 400%;

    -webkit-animation: gradient-loader-nft-effect 6s ease infinite;
    -moz-animation: gradient-loader-nft-effect 6s ease infinite;
    animation: gradient-loader-nft-effect 6s ease infinite;
}

@-webkit-keyframes gradient-loader-nft-effect {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes gradient-loader-nft-effect {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes gradient-loader-nft-effect {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

.container-account .account-goatech-g212 {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
}

.flex-mint-fieno {
    display: flex;
    align-items: center;
    margin-left: 500px;
}
.container-mint-fieno {
    width: 550px;
}

.container-account h3 {
    font-family: 'Barlow', sans-serif;
    margin-top: 0px;
    text-transform: inherit;
    font-size: 35px;
}
.box-mint-fieno {
    margin-top: 10px;
    max-width: 100%;
    padding: 15px 15px;
    padding-bottom: 15px;
}
.box-mint-fieno-cols {
    display: flex;
}
.box-mint-fieno-col1 {
    flex: 150px 1;
}
.box-mint-fieno-col1 img {
    width: 100%;
}
.box-mint-fieno-col2 {
    flex: calc(100% - 150px) 1;
    margin-left: 10px;
}
.box-mint-fieno .boxinput-mint-qnt {
    margin-bottom: 10px;
}

.goatech-stats-cols {
    display: flex;
    flex-direction: row;
}
.goatech-stats-cols .goatech-stats-col1 {
    flex: 1;
}
.goatech-stats-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 440px;
    margin-top: 10px;
}
.goatech-stats-tabs .goatech-stats-tab {
    flex: 50%;
}
.goatech-stats-tabs .goatech-stats-tab:nth-child(1),
.goatech-stats-tabs .goatech-stats-tab:nth-child(3),
.goatech-stats-tabs .goatech-stats-tab:nth-child(5) {
    padding-right: 15px;
}
.goatech-stats-tabs .goatech-stats-tab .stats-tab-box {
    background: linear-gradient(180deg, #6F6F6F 0%, rgba(162, 162, 162, 0) 100%);
    border-radius: 15px;
    height: 80px;
    margin-bottom: 10px;
    padding: 11px 15px;
}
.goatech-stats-cols .goatech-stats-col60 {
    flex: 60%;
}
.goatech-stats-cols .goatech-stats-col40 {
    flex: 40%;
}
.goatech-stats h3 {
    margin-bottom: 5px;
}
.goatech-stats h3:not(:first-child) {
    margin-top: 20px;
}
.stats-tab-title {
    margin-top: 4px;
    margin-bottom: 7px;
    font-weight: 600;
}
.stats-tab-valore {
    margin: 0px;
}

.goatech-stats-farmhousebarn {
    background-size: contain !important;
    position: absolute;
    height: 350px;
    width: 520px;
    background-repeat: no-repeat !important;
    z-index: -1;
    background-position: 0px -78px !important;
    right: 70px;
}
.goatech-stats-gt212 {
    background-size: contain !important;
    position: absolute;
    height: 420px;
    width: 310px;
    background-repeat: no-repeat !important;
    z-index: -1;
    background-position: left !important;
    right: 20px;
    top: -170px;
}
.goatech-stats-table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
}
.goatech-stats-table td, .goatech-stats-table th {
    border: 1px solid #7A7A7A;
    padding: 10px 15px;
}
.goatech-stats-table thead {
    background: #969696;
}
.goatech-stats-table thead th {
    font-weight: bold;
}
.goatech-stats-table tbody {
    background: linear-gradient(180deg, #6F6F6F 0%, rgba(162, 162, 162, 0) 100%);
}
.cell-left {
    text-align: left;
}
.goatech-stats-table-wrapper {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #353535;
}
.goatech-stats-tab .stats-table-left {
    text-align: left;
}

.goatech-stats-tab .stats-tab-percentgreen {
    color: #00FF47;
}
.goatech-stats-tab .stats-tab-percentred {
    color: #FD4848;
}
.stats-green {
    color: #00FF47;
}
.stats-red {
    color: #FD4848;
}
.stats-badge {
    padding: 0px 12px;
    border-radius: 10px;
    font-size: 16px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
}
.stats-badge.badge-active {
    background-color: #65DD5A;
}
.stats-badge.badge-pending {
    background-color: #F1C248;
    color: black;
}
.stats-badge.badge-request {
    background-color: #DE8451;
}
.stats-badge.badge-burned {
    background-color: #FD4848;
}
.burn-btn {
    padding: 0px 12px;
    border-radius: 10px;
    font-size: 16px;
    vertical-align: top;
    background-color: black;
    text-align: center;
    display: inline-block;
}
.burn-btn.disabled {
    opacity: 0.4;
}
.burn-btn:not(.disabled) {
    cursor: pointer;
}
.goatech-mobile-btns {
    display: none;
}
.goatech-mobile-btns a {
    flex: 1 1;
    background-color: black;
    color: white;
    border: 0px;
    border-radius: 15px;
    text-align: center;
    text-decoration: none !important;
    margin-right: 5px;
    padding: 5px 5px;
    background: linear-gradient(180deg, #5b5b5b 0%, rgb(40, 40, 40) 100%);

    display: flex;
    align-items: center;
    justify-content: center;
}
.goatech-mobile-btns a:last-child {
    margin-right: 0px;
}

.nav-admin>a {
    background-color: #e6b551;
    color: black !important;
    font-weight: 600;
}
.nav-admin.nav-active>a {
    color: white !important;
}

.subnav-ul-3n {
    height: 110px;
}
.subnav-ul-2n {
    height: 68px;
}

.form-trans-update input[type="text"],
.form-trans-update input[type="number"] {
    max-width: 360px;
    padding: 10px 18px;
    border: 0px;
    border-radius: 20px;
    outline: none !important;
    margin-right: 10px;
    width: 100%;
}
.form-trans-update input[type="submit"] {
    padding: 10px 18px;
    border-radius: 20px;
    border: 0px;
    outline: none !important;
    background-color: black;
    color: white;
    cursor: pointer;
    
}
.form-trans-update input {
    font-size: 15px;
}

.container-switch-network {
    padding: 20px;
    text-align: center;
}
.container-switch-network .text-switch-network {
    position: relative;
    z-index: 2;
    margin-top: 50px;
}
.container-switch-network .text-switch-network h3 {
    line-height: 40px;
}
.container-switch-network .text-switch-network p.switch-network-description {
    font-size: 19px;
    margin-top: 15px;
}
.container-switch-network .text-switch-network p.switch-network-btn {
    margin-top: 70px;
}
.btn-block-orange {
    background-color: #e6b551;
    padding: 10px 25px;
    border-radius: 20px;
    color: black !important;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    font-size: 19px;
}

.mint-fieno-spinner {
    text-align: center;
    font-size: 40px;
}
.mint-fieno-spinner p {
    font-size: 21px;
    text-align: center;
}

.container-mint-fieno .mint-failed-tx {
    text-align: center;
}
.container-mint-fieno .titleTxComplete {
    font-size: 30px;
    text-align: center;
}
.container-mint-fieno .mint-completed {
    text-align: center;
}
.container-mint-fieno .mint-completed a {
    color: #9ccc65 !important;
    text-decoration: none !important;
    font-size: 20px;
    border-bottom: 1px solid;
}